import React, { useState, useEffect } from "react";
import { Box, Typography, IconButton, InputLabel, FormControlLabel, Checkbox, Select, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Paper, Button, Grid, Menu, MenuItem, FormControl } from '@mui/material';
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { Helmet } from 'react-helmet-async';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "../../utils/axios";
import { NavLink, useNavigate } from "react-router-dom";
import Header from "../../assets/charts/Header";
import { useUser } from "../../contexts/auth";
import { useTheme } from "@mui/material/styles";
import '../../App.css'
import ViewListIcon from '@mui/icons-material/ViewList'; // Import the List View icon
import ViewKanbanIcon from '@mui/icons-material/ViewKanban';
import GridViewIcon from '@mui/icons-material/GridView';
import TableViewIcon from '@mui/icons-material/TableView';
import TocIcon from '@mui/icons-material/Toc';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
const AllContact = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [contacts, setContactsId] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [deletecontactId, setDeleteContactId] = useState(null);
  const [deleteSuccessMessage, setDeleteSuccessMessage] = useState(null);
  const { userrole, companycode } = useUser();
  const [selectedView, setSelectedView] = useState('All Contacts');
  const [viewAnchorEl, setViewAnchorEl] = useState(null);
  const [systemFiltersVisible, setSystemFiltersVisible] = useState(true);
  const [fieldFiltersVisible, setFieldFiltersVisible] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [alphabetFilter, setAlphabetFilter] = useState('');
  const [nameFilter, setNameFilter] = useState('All');
  const [filteredContacts, setFilteredContacts] = useState(contacts);

  // const systemDefinedFilters = [
  //   "Touched Records",
  //   "Untouched Records",
  //   "Record Action",
  //   "Related Records Action",
  //   "Locked",
  //   "Latest Email Status",
  //   "Activities",
  //   "Notes",
  //   "Deals",
  //   "Deal Amount",
  //   "Deal Stage",
  //   "Deal Owner",
  //   "Deal Closing Date",
  //   "Campaigns",
  //   "Cadences"
  // ];
  const [systemDefinedFilters, setSystemDefinedFilters] = useState({
    touchedRecords: { enabled: false, by: 'User & System', timeRange: '2 days' },
    untouchedRecords: { enabled: false, by: 'User & System', timeRange: '2 days' },
    recordAction: { enabled: false, actionType: 'Modified', by: 'User & System', timeRange: '2 days' },
    relatedRecordsAction: { enabled: false, actionType: 'Done', by: 'User & System', timeRange: '2 days' },
    latestEmailStatus: { enabled: false, status: 'sent', timeRange: '2 days', isBounced: false },
    activities: { enabled: false, type: 'Activity Due', overdueOption: 'Today + Overdue' },
    notes: { enabled: false, withoutNotes: true, timeRange: '2 days' },
    activities: { enabled: false, type: 'Activity Due', overdueOption: 'Today + Overdue' },
    campaigns: { enabled: false, type: '', status: '', name: '' },
    deals: { enabled: false, dealType: 'With Open Deal', timeRange: '1 month' },
    dealAmount: { enabled: false, amount: '', operator: '-' },
    dealStage: { enabled: false, stage: '' },
    dealOwner: { enabled: false, owner: '' },
    dealClosingDate: { enabled: false, timeRange: '2 days' },
  });
  const fieldFilters = [
    "Account Name",
    "Assistant",
    "Asst Phone",
    "Contact Name",
    "Contact Owner",
    "Created By",
    "Created Time",
    "Date of Birth",
    "Department",
    "Email",
    "Email Opt Out",
    "Fax",
    "First Name",
    "Home Phone",
    "Last Activity Time",
    "Last Name",
    "Lead Source",
    "Mailing City",
    "Mailing Country",
    "Mailing State",
    "Mailing Street",
    "Mailing Zip",
    "Mobile",
    "Modified By",
    "Modified Time",
    "Other City",
    "Other Country",
    "Other Phone",
    "Other State",
    "Other Street",
    "Other Zip",
    "Phone",
    "Reporting To",
    "Salutation",
    "Secondary Email",
    "Skype ID",
    "Tag",
    "Title",
    "Twitter",
    "Unsubscribed Mode",
    "Unsubscribed Time",
    "Vendor Name"
  ];
  const [filteredFieldFilters, setFilteredFieldFilters] = useState(fieldFilters);
  const toggleSystemFilters = () => {
    setSystemFiltersVisible(!systemFiltersVisible);
  };

  const toggleFieldFilters = () => {
    setFieldFiltersVisible(!fieldFiltersVisible);
  };

  // const handleSearchChange = (event) => {
  //   setSearchTerm(event.target.value.toLowerCase());
  // };
  const handleSearchChange = (event) => {
    const newSearchTerm = event.target.value.toLowerCase(); // Get the new search term
    setSearchTerm(newSearchTerm); // Update the search term
  
    // Filter the fieldFilters using the new search term
    const filteredFieldFilters = fieldFilters.filter((filter) =>
        filter.toLowerCase().includes(newSearchTerm) // Use newSearchTerm for filtering
    );
  
    // Update the state with filtered field filters
    setFilteredFieldFilters(filteredFieldFilters); // Update the filtered results state
    console.log(filteredFieldFilters, "-----"); // Check the filtered results
  };
  // const filteredSystemDefinedFilters = systemDefinedFilters.filter((filter) =>
  //   filter.toLowerCase().includes(searchTerm)
  // );

  // const filteredFieldFilters = fieldFilters.filter((filter) =>
  //   filter.toLowerCase().includes(searchTerm)
  // );

  const handleViewMenuOpen = (event) => {
    setViewAnchorEl(event.currentTarget);
  };

  const handleChange = (event) => {
    setSelectedView(event.target.value);
  };

  const handleViewMenuClose = () => {
    setViewAnchorEl(null);
  };

  const viewMenuOpen = Boolean(viewAnchorEl);

  const handleNameFilterChange = (event) => {
    setNameFilter(event.target.value);
  };

  useEffect(() => {
    setFilteredContacts(
      contacts.filter(contact => {
        if (nameFilter === 'All') return true;
        return contact.contactName && contact.contactName.charAt(0).toUpperCase() === nameFilter;
      })
    );
  }, [nameFilter, contacts]);

  const columns = [
    { 
      field: "firstname", 
      headerName: "Contact Name", 
      minWidth: 160,flex: 0.3,
      renderCell: (params) => (
        <NavLink to={`/contactdetails/${params.row.contactid}`}>
          {params.row.firstname}
        </NavLink>
      )
    },
    { field: "accountname", headerName: "Account Name", minWidth: 160,flex: 0.3 },
    { field: "email", headerName: "Email", minWidth: 160,flex: 0.3 },
    { field: "phone", headerName: "Phone", minWidth: 160,flex: 0.3 },
    { field: "contactowner", headerName: "Contact Owner", minWidth: 160,flex: 0.3 },
    {
      headerName: "Actions",
      minWidth: 120,flex: 0.3,
      renderCell: (props) => (
        <>
          <EditIcon
            style={{ marginRight: "10px" }}
            onClick={() => handleEdit(props.row.contactid)}
          />
          <DeleteIcon onClick={() => handleDelete(props.row.contactid)} />
        </>
      ),
    },
    { field: "leadsource", headerName: "Lead Source", minWidth: 160,flex: 0.3 },
    { field: "title", headerName: "Title", minWidth: 160,flex: 0.3 },
    { field: "department", headerName: "Department", minWidth: 160,flex: 0.3 },
    { field: "homephone", headerName: "Home Phone", minWidth: 160,flex: 0.3 },
    { field: "otherphone", headerName: "Other Phone", minWidth: 160,flex: 0.3 },
    { field: "fax", headerName: "Fax", minWidth: 160,flex: 0.3 },
    { field: "dateofbirth", headerName: "Date Of Birth", minWidth: 160,flex: 0.3 },
    { field: "assistant", headerName: "Assistant", minWidth: 160,flex: 0.3 },
    { field: "asstphone", headerName: "Asst Phone", minWidth: 160,flex: 0.3 },
    { field: "skypeid", headerName: "skype Id", minWidth: 160,flex: 0.3 },
    { field: "secondaryemail", headerName: "Secondary Email", minWidth: 160,flex: 0.3 },
    { field: "twitter", headerName: "Twitter", minWidth: 160,flex: 0.3 },
    { field: "reportingto", headerName: "Reporting To", minWidth: 160,flex: 0.3 },
    { field: "mailingstreet", headerName: "Mailing Street", minWidth: 160,flex: 0.3 },
    { field: "mailingcity", headerName: "City", minWidth: 160,flex: 0.3 },
    { field: "otherCity", headerName: "Other City", minWidth: 160,flex: 0.3 },
    { field: "mailingstate", headerName: "Mailing State", minWidth: 160,flex: 0.3 },
    { field: "mailingzip", headerName: "Mailing Zip", minWidth: 160,flex: 0.3 },
    { field: "otherzip", headerName: "Other Zip", minWidth: 160,flex: 0.3 },
    { field: "mailingcountry", headerName: "Mailing Country", minWidth: 160,flex: 0.3 },
    { field: "Othercountry", headerName: "Other Country", minWidth: 160,flex: 0.3 },
    { field: "description", headerName: "Description", minWidth: 160,flex: 0.3 },
    { field: "companycodes", headerName: "Companycode", minWidth: 160,flex: 0.3 },
  ];


  const fetchData = async () => {
    try {
      if (companycode !== undefined && userrole !== undefined) {
        const response = await axios.get("/getcontacts", {
          params: {
            companycode: companycode,
            userrole: userrole,
          },
        });
        if (response && response.data) {
          setContactsId(response.data);
        }
      }
    } catch (error) {
      console.error("Error fetching leads:", error);
    }
  };
  const fetchtodaycontact = async () => {
    try {
      if (companycode !== undefined && userrole !== undefined) {
        const response = await axios.get("/gettodaycontact", {
          params: {
            companycode: companycode,
            userrole: userrole,
          },
        });
        if (response && response.data) {
          setContactsId(response.data); // Update junk leads based on the response
        }
      }
    } catch (error) {
      console.error("Error fetching junk leads:", error);
    }
  };
  const fetchthisweekcontact = async () => {
    try {
      if (companycode !== undefined && userrole !== undefined) {
        const response = await axios.get("/getthisweekcontact", {
          params: {
            companycode: companycode,
            userrole: userrole,
          },
        });
        if (response && response.data) {
          setContactsId(response.data); // Update junk leads based on the response
        }
      }
    } catch (error) {
      console.error("Error fetching junk leads:", error);
    }
  };

  const fetchlastweekcontact = async () => {
    try {
      if (companycode !== undefined && userrole !== undefined) {
        const response = await axios.get("/getlastweekcontact", {
          params: {
            companycode: companycode,
            userrole: userrole,
          },
        });
        if (response && response.data) {
          setContactsId(response.data); // Update junk leads based on the response
        }
      }
    } catch (error) {
      console.error("Error fetching junk leads:", error);
    }
  };

  const fetchrecentaddedcontact = async () => {
    try {
      if (companycode !== undefined && userrole !== undefined) {
        const response = await axios.get("/getaddedcontact", {
          params: {
            companycode: companycode,
            userrole: userrole,
          },
        });
        if (response && response.data) {
          setContactsId(response.data); // Update junk leads based on the response
        }
      }
    } catch (error) {
      console.error("Error fetching junk leads:", error);
    }
  };

  const fetchrecentmodifiedcontact = async () => {
    try {
      if (companycode !== undefined && userrole !== undefined) {
        const response = await axios.get("/getmodifiedcontact", {
          params: {
            companycode: companycode,
            userrole: userrole,
          },
        });
        if (response && response.data) {
          setContactsId(response.data); // Update junk leads based on the response
        }
      }
    } catch (error) {
      console.error("Error fetching junk leads:", error);
    }
  };

  useEffect(() => {
    if (selectedView === "Today's Contact") {
      fetchtodaycontact(); // Fetch junk leads if "Junk Leads" is selected
    } 
    else if (selectedView === "New This Week") {
      fetchthisweekcontact(); // Fetch junk leads if "Junk Leads" is selected
    } 
    else if (selectedView === "New Last Week") {
      fetchlastweekcontact(); // Fetch junk leads if "Junk Leads" is selected
    } 
    else if (selectedView === "Recently Created Contacts") {
      fetchrecentaddedcontact(); // Fetch junk leads if "Junk Leads" is selected
    } 
    else if (selectedView === "Recently Modified Contacts") {
      fetchrecentmodifiedcontact(); // Fetch junk leads if "Junk Leads" is selected
    } 
    else {
      fetchData(); // Fetch other leads based on selected view
    }
  }, [companycode, userrole, selectedView]); // Dependency array includes selectedView
  // useEffect(() => {
  //   fetchData();
  // }, [companycode, userrole]);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleDelete = (contactId) => {
    setDeleteContactId(contactId);
    setOpenDialog(true);
  };
  const [fieldSearchTerms, setFieldSearchTerms] = useState({});

  const handleEdit = (contactid) => {
    navigate(`/updatecontact/${contactid}`);
  };

  const handleDialogOpen = (contactid) => {
    setDeleteContactId(contactid);
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await axios.delete(`/deletecontact/${deletecontactId}`);
      if (response && response.status === 200) {
        setContactsId((prevLeads) =>
          prevLeads.filter((accounts) => accounts.contactid !== deletecontactId)
        );
        setDeleteSuccessMessage("Contact deleted successfully!");
        setTimeout(() => {
          setDeleteSuccessMessage(null);
        }, 3000);
      } else {
        console.error("Failed to delete contact");
      }
    } catch (error) {
      console.error("Error deleting contacts:", error);
    }
    setOpenDialog(false);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const menuOpen = Boolean(anchorEl);
  const [selectedSystemFilters, setSelectedSystemFilters] = useState([]);
  const [selectedFieldFilters, setSelectedFieldFilters] = useState([]);
  // Handle system filter checkbox selection
// const handleSystemFilterChange = (event) => {
//   const { name, checked } = event.target;
//   setSelectedSystemFilters((prev) =>
//     checked ? [...prev, name] : prev.filter((filter) => filter !== name)
//   );
// };
// General function to handle filter changes
const handleSystemFilterChange = (filterKey, field, value) => {
  setSystemDefinedFilters((prevState) => ({
    ...prevState,
    [filterKey]: {
      ...prevState[filterKey],
      [field]: value,
    },
  }));
};
// Handle field filter checkbox selection
// const handleFieldFilterChange = (event) => {
//   const { name, checked } = event.target;
//   setSelectedFieldFilters((prev) =>
//     checked ? [...prev, name] : prev.filter((filter) => filter !== name)
//   );
// };
const handleFieldFilterChange = (event) => {
  const { name, checked } = event.target;
  if (checked) {
    setSelectedFieldFilters((prev) => [...prev, name]);
    setFieldSearchTerms((prev) => ({ ...prev, [name]: "" }));
  } else {
    setSelectedFieldFilters((prev) => prev.filter((filter) => filter !== name));
    setFieldSearchTerms((prev) => {
      const { [name]: removed, ...rest } = prev;
      return rest;
    });
  }
};
const handleFieldSearchChange = (event, filterName) => {
  console.log("----------------")
  setFieldSearchTerms((prev) => ({
    ...prev,
    [filterName]: event.target.value
  }));
};

  useEffect(() => {
    let updatedcontacts = contacts;
  
    // Apply system filters
    if (selectedSystemFilters.length > 0) {
      updatedcontacts = updatedcontacts.filter((accounts) => {
        // Add your system-defined filtering logic here.
        // For demonstration, I'm just filtering by a simple condition.
        // You'll need to customize this based on the system filters.
        return selectedSystemFilters.every((filter) =>
          accounts[filter.toLowerCase().replace(/\s+/g, '')]
        );
      });
    }
  
// Apply field filters
if (selectedFieldFilters.length > 0) {
  updatedcontacts = updatedcontacts.filter((lead) => {
    return selectedFieldFilters.every((filter) => {
      const field = filter.toLowerCase().replace(/\s+/g, '');
      return fieldSearchTerms[filter] ? lead[field]?.includes(fieldSearchTerms[filter]) : true;
    });
  });
}
  
    setFilteredContacts(updatedcontacts);
  }, [selectedSystemFilters, selectedFieldFilters,contacts,fieldSearchTerms]);
  return (
    <Box>
         <Helmet>
      <title>Contacts - Contacttab.com</title>
    </Helmet>

      {/* Header Section */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2, backgroundColor: 'white', color: 'black', marginTop: "5.2%", padding: '5px'}}>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small" style={{color: 'white', background: 'black', borderRadius: '5px'}}>
          <Select
            labelId="accounts-select-label"
            value={selectedView}
            onChange={handleChange}
            label="Public Views"
            style={{color: 'white'}}
          >
            <MenuItem value="All Contacts">All Contacts</MenuItem>
            <MenuItem value="Today's Contact">Today's Contact</MenuItem>
            <MenuItem value="New This Week">New This Week</MenuItem>
            <MenuItem value="New Last Week">New Last Week</MenuItem>
            <MenuItem value="Recently Created Contacts">Recently Created Contacts</MenuItem>
            <MenuItem value="Recently Modified Contacts">Recently Modified Contacts</MenuItem>
            <MenuItem value="All Locked Contacts">All Locked Contacts</MenuItem>
            <MenuItem value="Mailing Labels">Mailing Labels</MenuItem>
            <MenuItem value="My Contacts">My Contacts</MenuItem>
            <MenuItem value="Unread Contacts">Unread Contacts</MenuItem>
            <MenuItem value="Unsubscribed Contacts">Unsubscribed Contacts</MenuItem>
          </Select>
        </FormControl>
        <Box>
          <Button variant="contained" onClick={handleViewMenuOpen} startIcon={<ViewListIcon />}>List View</Button> {/* Added List View Button */}
          <Button variant="contained" color="primary" sx={{ mr: 2 }} onClick={() => navigate('/create-contact')}>Create Contact</Button>
          <Button variant="contained" onClick={handleMenuOpen}>Actions</Button>
          <Menu anchorEl={anchorEl} open={menuOpen} onClose={handleMenuClose}>
            <MenuItem onClick={handleMenuClose}>Mass Delete</MenuItem>
            <MenuItem onClick={handleMenuClose}>Mass Update</MenuItem>
            <MenuItem onClick={handleMenuClose}>Mass Convert</MenuItem>
            <MenuItem onClick={handleMenuClose}>Manage Tags</MenuItem>
            <MenuItem onClick={handleMenuClose}>Drafts</MenuItem>
            <MenuItem onClick={handleMenuClose}>Mass Email</MenuItem>
            <MenuItem onClick={handleMenuClose}>Autoresponders</MenuItem>
            <MenuItem onClick={handleMenuClose}>Approve Contacts</MenuItem>
            <MenuItem onClick={handleMenuClose}>Deduplicate Contacts</MenuItem>
            <MenuItem onClick={handleMenuClose}>Add to Campaigns</MenuItem>
            <MenuItem onClick={handleMenuClose}>Create Client Script</MenuItem>
            <MenuItem onClick={handleMenuClose}>Export Contacts</MenuItem>
            <MenuItem onClick={handleMenuClose}>Zoho Sheet View</MenuItem>
            <MenuItem onClick={handleMenuClose}>Print View</MenuItem>
          </Menu>
          <Menu anchorEl={viewAnchorEl} open={viewMenuOpen} onClose={handleViewMenuClose}> {/* Added List View Menu */}
            <MenuItem onClick={handleViewMenuClose}><span style={{marginRight: '10px', marginLeft: '-5px'}}><TocIcon /></span> List View</MenuItem>
            <MenuItem onClick={handleViewMenuClose}><span style={{marginRight: '10px', marginLeft: '-5px'}}><ViewKanbanIcon /></span> Kanban View</MenuItem>
            <MenuItem onClick={handleViewMenuClose}><span style={{marginRight: '10px', marginLeft: '-5px'}}><ViewListIcon /></span> Custom List View</MenuItem>
            <MenuItem onClick={handleViewMenuClose}><span style={{marginRight: '10px', marginLeft: '-5px'}}><GridViewIcon /></span> Tile View</MenuItem>
            <MenuItem onClick={handleViewMenuClose}><span style={{marginRight: '10px', marginLeft: '-5px'}}><TableViewIcon /></span> Table View</MenuItem>
          </Menu>
        </Box>
      </Box>

      {/* Main Content */}
      <Grid container columnGap={5}> 
        <Grid item xs={2}>
          <Box
            sx={{
              p: 2,
              borderRight: "1px solid #e0e0e0",
              height: "76vh",
              background: "black",
              overflowY: "auto",
              marginTop: "20px"
            }}
          >
            <Typography variant="h6" color="white" style={{fontSize: "16px"}}>Filter Contacts by</Typography>
            <TextField
              variant="outlined"
              placeholder="Search"
              size="small"
              fullWidth
              sx={{ mb: 2 }}
              value={searchTerm}
              onChange={handleSearchChange}
            />

            <Typography
              variant="body2"
              color="white"
              sx={{ mb: 1, cursor: "pointer", fontSize: "16px"}}
              onClick={toggleSystemFilters}
            >
              System Defined Filters <IconButton
                sx={{
                  color: "white",
                  transform: systemFiltersVisible ? 'rotate(90deg)' : 'rotate(0deg)',
                  transition: 'transform 0.3s'
                }}
              >
                <ArrowRightIcon />
              </IconButton>
            </Typography>
            {/* {systemFiltersVisible && (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                {filteredSystemDefinedFilters.map((filter) => (
                  <FormControlLabel
                    key={filter}
                    control={
                    <Checkbox 
                    name={filter}
                    onChange={handleSystemFilterChange}
                    checked={selectedSystemFilters.includes(filter)} 
                    />}
                    label={filter}
                    sx={{ color: "white" }}
                  />
                ))}
              </Box>
            )} */}
{systemFiltersVisible && (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>

        {/* Touched Records Filter */}
        <FormControlLabel
          control={
            <Checkbox
              checked={systemDefinedFilters.touchedRecords.enabled}
              onChange={() =>
                handleSystemFilterChange('touchedRecords', 'enabled', !systemDefinedFilters.touchedRecords.enabled)
              }
            />
          }
          label="Touched Records"
        />
        {systemDefinedFilters.touchedRecords.enabled && (
          <>
            <Select
              value={systemDefinedFilters.touchedRecords.by}
              onChange={(event) => handleSystemFilterChange('touchedRecords', 'by', event.target.value)}
            >
              <MenuItem value="User & System">User & System</MenuItem>
              <MenuItem value="User Only">User Only</MenuItem>
              <MenuItem value="System Only">System Only</MenuItem>
            </Select>
            <Select
              value={systemDefinedFilters.touchedRecords.timeRange}
              onChange={(event) => handleSystemFilterChange('touchedRecords', 'timeRange', event.target.value)}
            >
              <MenuItem value="2 days">in the last 2 days</MenuItem>
              <MenuItem value="7 days">in the last 7 days</MenuItem>
              <MenuItem value="30 days">in the last 30 days</MenuItem>
            </Select>
          </>
        )}

        {/* Untouched Records Filter */}
        <FormControlLabel
          control={
            <Checkbox
              checked={systemDefinedFilters.untouchedRecords.enabled}
              onChange={() =>
                handleSystemFilterChange('untouchedRecords', 'enabled', !systemDefinedFilters.untouchedRecords.enabled)
              }
            />
          }
          label="Untouched Records"
        />
        {systemDefinedFilters.untouchedRecords.enabled && (
          <>
            <Select
              value={systemDefinedFilters.untouchedRecords.by}
              onChange={(event) => handleSystemFilterChange('untouchedRecords', 'by', event.target.value)}
            >
              <MenuItem value="User & System">User & System</MenuItem>
              <MenuItem value="User Only">User Only</MenuItem>
              <MenuItem value="System Only">System Only</MenuItem>
            </Select>
            <Select
              value={systemDefinedFilters.untouchedRecords.timeRange}
              onChange={(event) => handleSystemFilterChange('untouchedRecords', 'timeRange', event.target.value)}
            >
              <MenuItem value="2 days">in the last 2 days</MenuItem>
              <MenuItem value="7 days">in the last 7 days</MenuItem>
              <MenuItem value="30 days">in the last 30 days</MenuItem>
            </Select>
          </>
        )}

        {/* Record Action Filter */}
        <FormControlLabel
          control={
            <Checkbox
              checked={systemDefinedFilters.recordAction.enabled}
              onChange={() =>
                handleSystemFilterChange('recordAction', 'enabled', !systemDefinedFilters.recordAction.enabled)
              }
            />
          }
          label="Record Action"
        />
        {systemDefinedFilters.recordAction.enabled && (
          <>
            <Select
              value={systemDefinedFilters.recordAction.actionType}
              onChange={(event) => handleSystemFilterChange('recordAction', 'actionType', event.target.value)}
            >
              <MenuItem value="Modified">Modified</MenuItem>
              <MenuItem value="Added">Added</MenuItem>
              <MenuItem value="Deleted">Deleted</MenuItem>
            </Select>
            <Select
              value={systemDefinedFilters.recordAction.by}
              onChange={(event) => handleSystemFilterChange('recordAction', 'by', event.target.value)}
            >
              <MenuItem value="User & System">User & System</MenuItem>
              <MenuItem value="User Only">User Only</MenuItem>
              <MenuItem value="System Only">System Only</MenuItem>
            </Select>
            <Select
              value={systemDefinedFilters.recordAction.timeRange}
              onChange={(event) => handleSystemFilterChange('recordAction', 'timeRange', event.target.value)}
            >
              <MenuItem value="2 days">in the last 2 days</MenuItem>
              <MenuItem value="7 days">in the last 7 days</MenuItem>
              <MenuItem value="30 days">in the last 30 days</MenuItem>
            </Select>
          </>
        )}

        {/* Related Records Action Filter */}
        <FormControlLabel
          control={
            <Checkbox
              checked={systemDefinedFilters.relatedRecordsAction.enabled}
              onChange={() =>
                handleSystemFilterChange('relatedRecordsAction', 'enabled', !systemDefinedFilters.relatedRecordsAction.enabled)
              }
            />
          }
          label="Related Records Action"
        />
        {systemDefinedFilters.relatedRecordsAction.enabled && (
          <>
            <Select
              value={systemDefinedFilters.relatedRecordsAction.actionType}
              onChange={(event) => handleSystemFilterChange('relatedRecordsAction', 'actionType', event.target.value)}
            >
              <MenuItem value="Done">Done</MenuItem>
              <MenuItem value="Pending">Pending</MenuItem>
            </Select>
            <Select
              value={systemDefinedFilters.relatedRecordsAction.by}
              onChange={(event) => handleSystemFilterChange('relatedRecordsAction', 'by', event.target.value)}
            >
              <MenuItem value="User & System">User & System</MenuItem>
              <MenuItem value="User Only">User Only</MenuItem>
              <MenuItem value="System Only">System Only</MenuItem>
            </Select>
            <Select
              value={systemDefinedFilters.relatedRecordsAction.timeRange}
              onChange={(event) => handleSystemFilterChange('relatedRecordsAction', 'timeRange', event.target.value)}
            >
              <MenuItem value="2 days">in the last 2 days</MenuItem>
              <MenuItem value="7 days">in the last 7 days</MenuItem>
              <MenuItem value="30 days">in the last 30 days</MenuItem>
            </Select>
          </>
        )}

        {/* Latest Email Status Filter */}
        <FormControlLabel
          control={
            <Checkbox
              checked={systemDefinedFilters.latestEmailStatus.enabled}
              onChange={() =>
                handleSystemFilterChange('latestEmailStatus', 'enabled', !systemDefinedFilters.latestEmailStatus.enabled)
              }
            />
          }
          label="Latest Email Status"
        />
        {systemDefinedFilters.latestEmailStatus.enabled && (
          <>
            <Select
              value={systemDefinedFilters.latestEmailStatus.status}
              onChange={(event) => handleSystemFilterChange('latestEmailStatus', 'status', event.target.value)}
            >
              <MenuItem value="sent">sent</MenuItem>
              <MenuItem value="failed">failed</MenuItem>
              <MenuItem value="queued">queued</MenuItem>
            </Select>
            <Select
              value={systemDefinedFilters.latestEmailStatus.timeRange}
              onChange={(event) => handleSystemFilterChange('latestEmailStatus', 'timeRange', event.target.value)}
            >
              <MenuItem value="2 days">in the last 2 days</MenuItem>
              <MenuItem value="7 days">in the last 7 days</MenuItem>
              <MenuItem value="30 days">in the last 30 days</MenuItem>
            </Select>
            <FormControlLabel
              control={
                <Checkbox
                  checked={systemDefinedFilters.latestEmailStatus.isBounced}
                  onChange={() =>
                    handleSystemFilterChange('latestEmailStatus', 'isBounced', !systemDefinedFilters.latestEmailStatus.isBounced)
                  }
                />
              }
              label="Bounced"
            />
          </>
        )}

        {/* Activities Filter */}
        <FormControlLabel
          control={
            <Checkbox
              checked={systemDefinedFilters.activities.enabled}
              onChange={() =>
                handleSystemFilterChange('activities', 'enabled', !systemDefinedFilters.activities.enabled)
              }
            />
          }
          label="Activities"
        />
        {systemDefinedFilters.activities.enabled && (
          <>
            <FormControlLabel
              control={
                <Radio
                  checked={systemDefinedFilters.activities.type === 'Without Open Activity'}
                  onChange={() => handleSystemFilterChange('activities', 'type', 'Without Open Activity')}
                />
              }
              label="Without Open Activity"
            />
            <FormControlLabel
              control={
                <Radio
                  checked={systemDefinedFilters.activities.type === 'Overdue'}
                  onChange={() => handleSystemFilterChange('activities', 'type', 'Overdue')}
                />
              }
              label="Overdue"
            />
            <FormControlLabel
              control={
                <Radio
                  checked={systemDefinedFilters.activities.type === 'Activity Due'}
                  onChange={() => handleSystemFilterChange('activities', 'type', 'Activity Due')}
                />
              }
              label="Activity Due"
            />
            {systemDefinedFilters.activities.type === 'Activity Due' && (
              <Select
                value={systemDefinedFilters.activities.overdueOption}
                onChange={(event) => handleSystemFilterChange('activities', 'overdueOption', event.target.value)}
              >
                <MenuItem value="Today + Overdue">Today + Overdue</MenuItem>
                <MenuItem value="Today">Today</MenuItem>
                <MenuItem value="Overdue">Overdue</MenuItem>
              </Select>
            )}
          </>
        )}
{/* Notes Filter */}
<FormControlLabel
      control={
        <Checkbox
          checked={systemDefinedFilters.notes.enabled}
          onChange={() => handleSystemFilterChange('notes', 'enabled', !systemDefinedFilters.notes.enabled)}
        />
      }
      label="Notes"
    />
    {systemDefinedFilters.notes.enabled && (
      <>
        <RadioGroup
          value={systemDefinedFilters.notes.withoutNotes ? 'withoutNotes' : 'withNotes'}
          onChange={(event) => handleSystemFilterChange('notes', 'withoutNotes', event.target.value === 'withoutNotes')}
        >
          <FormControlLabel value="withoutNotes" control={<Radio />} label="Without Any Notes" />
          <FormControlLabel value="withNotes" control={<Radio />} label="Notes Added" />
        </RadioGroup>
        <Select
          value={systemDefinedFilters.notes.timeRange}
          onChange={(event) => handleSystemFilterChange('notes', 'timeRange', event.target.value)}
        >
          <MenuItem value="2 days">in the last 2 days</MenuItem>
          <MenuItem value="7 days">in the last 7 days</MenuItem>
          <MenuItem value="30 days">in the last 30 days</MenuItem>
        </Select>
      </>
    )}

    {/* Deals Filter */}
    <FormControlLabel
      control={
        <Checkbox
          checked={systemDefinedFilters.deals.enabled}
          onChange={() => handleSystemFilterChange('deals', 'enabled', !systemDefinedFilters.deals.enabled)}
        />
      }
      label="Deals"
    />
    {systemDefinedFilters.deals.enabled && (
      <>
        <RadioGroup
          value={systemDefinedFilters.deals.dealType}
          onChange={(event) => handleSystemFilterChange('deals', 'dealType', event.target.value)}
        >
          <FormControlLabel value="With Open Deal" control={<Radio />} label="With Open Deal" />
          <FormControlLabel value="Without Open Deal" control={<Radio />} label="Without Open Deal" />
          <FormControlLabel value="Without Any Deal" control={<Radio />} label="Without Any Deal" />
        </RadioGroup>
        <Select
          value={systemDefinedFilters.deals.timeRange}
          onChange={(event) => handleSystemFilterChange('deals', 'timeRange', event.target.value)}
        >
          <MenuItem value="2 days">in the last 2 days</MenuItem>
          <MenuItem value="7 days">in the last 7 days</MenuItem>
          <MenuItem value="1 month">in the last 1 month</MenuItem>
        </Select>
      </>
    )}

    {/* Deal Amount */}
    <FormControlLabel
      control={
        <Checkbox
          checked={systemDefinedFilters.dealAmount.enabled}
          onChange={() => handleSystemFilterChange('dealAmount', 'enabled', !systemDefinedFilters.dealAmount.enabled)}
        />
      }
      label="Deal Amount"
    />
    {systemDefinedFilters.dealAmount.enabled && (
      <>
        <Select
          value={systemDefinedFilters.dealAmount.operator}
          onChange={(event) => handleSystemFilterChange('dealAmount', 'operator', event.target.value)}
        >
          <MenuItem value="-">-</MenuItem>
          <MenuItem value="+">+</MenuItem>
        </Select>
        <TextField
          placeholder="Rs."
          value={systemDefinedFilters.dealAmount.amount}
          onChange={(event) => handleSystemFilterChange('dealAmount', 'amount', event.target.value)}
        />
      </>
    )}

    {/* Deal Stage */}
    <FormControlLabel
      control={
        <Checkbox
          checked={systemDefinedFilters.dealStage.enabled}
          onChange={() => handleSystemFilterChange('dealStage', 'enabled', !systemDefinedFilters.dealStage.enabled)}
        />
      }
      label="Deal Stage"
    />
    {systemDefinedFilters.dealStage.enabled && (
      <Select
        value={systemDefinedFilters.dealStage.stage}
        onChange={(event) => handleSystemFilterChange('dealStage', 'stage', event.target.value)}
      >
        <MenuItem value="None">None</MenuItem>
        <MenuItem value="Stage 1">Stage 1</MenuItem>
        <MenuItem value="Stage 2">Stage 2</MenuItem>
      </Select>
    )}

    {/* Deal Owner */}
    <FormControlLabel
      control={
        <Checkbox
          checked={systemDefinedFilters.dealOwner.enabled}
          onChange={() => handleSystemFilterChange('dealOwner', 'enabled', !systemDefinedFilters.dealOwner.enabled)}
        />
      }
      label="Deal Owner"
    />
    {systemDefinedFilters.dealOwner.enabled && (
      <Select
        value={systemDefinedFilters.dealOwner.owner}
        onChange={(event) => handleSystemFilterChange('dealOwner', 'owner', event.target.value)}
      >
        <MenuItem value="User 1">User 1</MenuItem>
        <MenuItem value="User 2">User 2</MenuItem>
      </Select>
    )}

    {/* Deal Closing Date */}
    <FormControlLabel
      control={
        <Checkbox
          checked={systemDefinedFilters.dealClosingDate.enabled}
          onChange={() => handleSystemFilterChange('dealClosingDate', 'enabled', !systemDefinedFilters.dealClosingDate.enabled)}
        />
      }
      label="Deal Closing Date"
    />
    {systemDefinedFilters.dealClosingDate.enabled && (
      <Select
        value={systemDefinedFilters.dealClosingDate.timeRange}
        onChange={(event) => handleSystemFilterChange('dealClosingDate', 'timeRange', event.target.value)}
      >
        <MenuItem value="2 days">in the last 2 days</MenuItem>
        <MenuItem value="7 days">in the last 7 days</MenuItem>
        <MenuItem value="30 days">in the last 30 days</MenuItem>
      </Select>
    )}

    {/* Campaigns */}
    <FormControlLabel
      control={
        <Checkbox
          checked={systemDefinedFilters.campaigns.enabled}
          onChange={() => handleSystemFilterChange('campaigns', 'enabled', !systemDefinedFilters.campaigns.enabled)}
        />
      }
      label="Campaigns"
    />
    {systemDefinedFilters.campaigns.enabled && (
      <>
        <Select
          value={systemDefinedFilters.campaigns.type}
          onChange={(event) => handleSystemFilterChange('campaigns', 'type', event.target.value)}
        >
          <MenuItem value="Campaign Type 1">Campaign Type 1</MenuItem>
          <MenuItem value="Campaign Type 2">Campaign Type 2</MenuItem>
        </Select>
        <Select
          value={systemDefinedFilters.campaigns.status}
          onChange={(event) => handleSystemFilterChange('campaigns', 'status', event.target.value)}
        >
          <MenuItem value="Active">Active</MenuItem>
          <MenuItem value="Inactive">Inactive</MenuItem>
        </Select>
        <TextField
          placeholder="Campaign Name"
          value={systemDefinedFilters.campaigns.name}
          onChange={(event) => handleSystemFilterChange('campaigns', 'name', event.target.value)}
        />
      </>
    )}
      </Box>
    )}
            <Typography
              variant="body2"
              color="white"
              sx={{ mt: 2, mb: 1, cursor: "pointer", fontSize: "16px" }}
              onClick={toggleFieldFilters}
            >
              Filter By Fields <IconButton
                sx={{
                  color: "white",
                  transform: fieldFiltersVisible ? 'rotate(90deg)' : 'rotate(0deg)',
                  transition: 'transform 0.3s'
                }}
              >
                <ArrowRightIcon />
              </IconButton>
            </Typography>
            {fieldFiltersVisible && (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
            {filteredFieldFilters.map((filter) => (
              <Box key={filter}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedFieldFilters.includes(filter)}
                      onChange={handleFieldFilterChange}
                      name={filter}
                    />
                  }
                  label={filter}
                />
                {selectedFieldFilters.includes(filter) && (
                  <TextField
                    label={`Search ${filter}`}
                    value={fieldSearchTerms[filter] || ""}
                    onChange={(event) => handleFieldSearchChange(event, filter)}
                  />
                )}
              </Box>
            ))}
            </Box>
            )}
          </Box>
        </Grid>

        <Grid item xs={9.5}>
          <Paper elevation={3} style={{ padding: '20px', height: '76vh', overflow: 'auto', marginTop: '20px', color: 'black' }} className="listViewStyle">
            <Typography variant="h6" sx={{ mb: 2 }} style={{color: 'white'}}>Total Records: {filteredContacts.length}</Typography>
            {/* <DataGrid
              rows={filteredContacts}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[10]}
              checkboxSelection
              disableSelectionOnClick
            /> */}
            <DataGrid
              rows={filteredContacts}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[10]}
              checkboxSelection
              disableSelectionOnClick
              getRowId={(row) => row.contactid} // Specify contactid as the unique id
            />
            <Dialog open={openDialog} onClose={handleCloseDialog}>
              <DialogTitle>Confirm Delete</DialogTitle>
              <DialogContent>Are you sure you want to delete this accounts?</DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialog} color="primary">
                  No
                </Button>
                <Button onClick={handleConfirmDelete} color="primary" autoFocus>
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AllContact;
